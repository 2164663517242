* {
	box-sizing: border-box;
}
html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	height: 100%;
}
body {
	height: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: "Proxima Nova";
	line-height: 1.5
}

ul {

}

li {
	line-height: 2
}

p {
	line-height: 1.5
}

.text--brand {
	font-family: "Proxima Nova";
}

.text--semi-bold {
	font-family: "Proxima Nova Semibold";
}

h1,
h2,
h3,
h4,
h5 H6,
p,
a {
	margin: 0px;
	font-family: "Proxima Nova";
}

a:link {
	text-decoration: none;
	color: rgb(0, 101, 255);
}

a:visited {
	color: rgb(0, 101, 255);
}

a:focus {
	color: rgb(0, 101, 255);
}

a:hover {
	color: rgb(0, 101, 255);
}

a:active {
	color: rgb(0, 101, 255);
}

button {
	font-family: "Proxima Nova Semibold";
}

/* Margin top */
.m-t-0 {
	margin-top: 0;
}

.m-t-0-5 {
	margin-top: 0.125rem;
}

.m-t-1 {
	margin-top: 0.25rem;
}

.m-t-2 {
	margin-top: 0.5rem;
}

.m-t-3 {
	margin-top: 0.75rem;
}

.m-t-4 {
	margin-top: 1rem;
}

.m-t-5 {
	margin-top: 1.25rem;
}

.m-t-6 {
	margin-top: 1.5rem;
}

.m-t-7 {
	margin-top: 1.75rem;
}

.m-t-8 {
	margin-top: 2rem;
}

.m-t-9 {
	margin-top: 2.25rem;
}

.m-t-10 {
	margin-top: 2.5rem;
}

.m-t-11 {
	margin-top: 2.75rem;
}

.m-t-12 {
	margin-top: 3rem;
}

.m-t-13 {
	margin-top: 3.25rem;
}

.m-t-14 {
	margin-top: 3.5rem;
}

.m-t-15 {
	margin-top: 3.75rem;
}

.m-t-16 {
	margin-top: 4rem;
}

.m-t-17 {
	margin-top: 4.25rem;
}

.m-t-18 {
	margin-top: 4.5rem;
}

.m-t-19 {
	margin-top: 4.75rem;
}

.m-t-20 {
	margin-top: 5rem;
}

@media (max-width: 1024px) {
	.m-t-sm-0 {
		margin-top: 0;
	}

	.m-t-sm-0-5 {
		margin-top: 0.125rem;
	}

	.m-t-sm-1 {
		margin-top: 0.25rem;
	}

	.m-t-sm-2 {
		margin-top: 0.5rem;
	}

	.m-t-sm-3 {
		margin-top: 0.75rem;
	}

	.m-t-sm-4 {
		margin-top: 1rem;
	}

	.m-t-sm-5 {
		margin-top: 1.25rem;
	}

	.m-t-sm-6 {
		margin-top: 1.5rem;
	}

	.m-t-sm-7 {
		margin-top: 1.75rem;
	}

	.m-t-sm-8 {
		margin-top: 2rem;
	}

	.m-t-sm-9 {
		margin-top: 2.25rem;
	}

	.m-t-sm-10 {
		margin-top: 2.5rem;
	}

	.m-t-sm-11 {
		margin-top: 2.75rem;
	}

	.m-t-sm-12 {
		margin-top: 3rem;
	}

	.m-t-sm-13 {
		margin-top: 3.25rem;
	}

	.m-t-sm-14 {
		margin-top: 3.5rem;
	}

	.m-t-sm-15 {
		margin-top: 3.75rem;
	}

	.m-t-sm-16 {
		margin-top: 4rem;
	}

	.m-t-sm-17 {
		margin-top: 4.25rem;
	}

	.m-t-sm-18 {
		margin-top: 4.5rem;
	}

	.m-t-sm-19 {
		margin-top: 4.75rem;
	}

	.m-t-sm-20 {
		margin-top: 5rem;
	}
}

/* Margin bottom */
.m-b-0 {
	margin-bottom: 0;
}

.m-b-0-5 {
	margin-bottom: 0.125rem;
}

.m-b-1 {
	margin-bottom: 0.25rem;
}

.m-b-2 {
	margin-bottom: 0.5rem;
}

.m-b-3 {
	margin-bottom: 0.75rem;
}

.m-b-4 {
	margin-bottom: 1rem;
}

.m-b-5 {
	margin-bottom: 1.25rem;
}

.m-b-6 {
	margin-bottom: 1.5rem;
}

.m-b-7 {
	margin-bottom: 1.75rem;
}

.m-b-8 {
	margin-bottom: 2rem;
}

.m-b-9 {
	margin-bottom: 2.25rem;
}

.m-b-10 {
	margin-bottom: 2.5rem;
}

.m-b-11 {
	margin-bottom: 2.75rem;
}

.m-b-12 {
	margin-bottom: 3rem;
}

.m-b-13 {
	margin-bottom: 3.25rem;
}

.m-b-14 {
	margin-bottom: 3.5rem;
}

.m-b-15 {
	margin-bottom: 3.75rem;
}

.m-b-16 {
	margin-bottom: 4rem;
}

.m-b-17 {
	margin-bottom: 4.25rem;
}

.m-b-18 {
	margin-bottom: 4.5rem;
}

.m-b-19 {
	margin-bottom: 4.75rem;
}

.m-b-20 {
	margin-bottom: 5rem;
}

@media (max-width: 1024px) {
	.m-b-sm-0 {
		margin-bottom: 0;
	}

	.m-t-sm-0-5 {
		margin-bottom: 0.125rem;
	}

	.m-b-sm-1 {
		margin-bottom: 0.25rem;
	}

	.m-b-sm-2 {
		margin-bottom: 0.5rem;
	}

	.m-b-sm-3 {
		margin-bottom: 0.75rem;
	}

	.m-b-sm-4 {
		margin-bottom: 1rem;
	}

	.m-b-sm-5 {
		margin-bottom: 1.25rem;
	}

	.m-b-sm-6 {
		margin-bottom: 1.5rem;
	}

	.m-b-sm-7 {
		margin-bottom: 1.75rem;
	}

	.m-b-sm-8 {
		margin-bottom: 2rem;
	}

	.m-b-sm-9 {
		margin-bottom: 2.25rem;
	}

	.m-b-sm-10 {
		margin-bottom: 2.5rem;
	}

	.m-b-sm-11 {
		margin-bottom: 2.75rem;
	}

	.m-b-sm-12 {
		margin-bottom: 3rem;
	}

	.m-b-sm-13 {
		margin-bottom: 3.25rem;
	}

	.m-b-sm-14 {
		margin-bottom: 3.5rem;
	}

	.m-b-sm-15 {
		margin-bottom: 3.75rem;
	}

	.m-b-sm-16 {
		margin-bottom: 4rem;
	}

	.m-b-sm-17 {
		margin-bottom: 4.25rem;
	}

	.m-b-sm-18 {
		margin-bottom: 4.5rem;
	}

	.m-b-sm-19 {
		margin-bottom: 4.75rem;
	}

	.m-b-sm-20 {
		margin-bottom: 5rem;
	}
}

@media (max-width: 479px) {
	.m-b-xs-0 {
		margin-bottom: 0;
	}

	.m-b-xs-0-5 {
		margin-bottom: 0.125rem;
	}

	.m-b-xs-1 {
		margin-bottom: 0.25rem;
	}

	.m-b-xs-2 {
		margin-bottom: 0.5rem;
	}

	.m-b-xs-3 {
		margin-bottom: 0.75rem;
	}

	.m-b-xs-4 {
		margin-bottom: 1rem;
	}

	.m-b-xs-5 {
		margin-bottom: 1.25rem;
	}

	.m-b-xs-6 {
		margin-bottom: 1.5rem;
	}

	.m-b-xs-7 {
		margin-bottom: 1.75rem;
	}

	.m-b-xs-8 {
		margin-bottom: 2rem;
	}

	.m-b-xs-9 {
		margin-bottom: 2.25rem;
	}

	.m-b-xs-10 {
		margin-bottom: 2.5rem;
	}

	.m-b-xs-11 {
		margin-bottom: 2.75rem;
	}

	.m-b-xs-12 {
		margin-bottom: 3rem;
	}

	.m-b-xs-13 {
		margin-bottom: 3.25rem;
	}

	.m-b-xs-14 {
		margin-bottom: 3.5rem;
	}

	.m-b-xs-15 {
		margin-bottom: 3.75rem;
	}

	.m-b-xs-16 {
		margin-bottom: 4rem;
	}

	.m-b-xs-17 {
		margin-bottom: 4.25rem;
	}

	.m-b-xs-18 {
		margin-bottom: 4.5rem;
	}

	.m-b-xs-19 {
		margin-bottom: 4.75rem;
	}

	.m-b-xs-20 {
		margin-bottom: 5rem;
	}
}

/* Margin left */
.m-l-0 {
	margin-left: 0;
}

.m-l-0-5 {
	margin-left: 0.125rem;
}

.m-l-1 {
	margin-left: 0.25rem;
}

.m-l-2 {
	margin-left: 0.5rem;
}

.m-l-3 {
	margin-left: 0.75rem;
}

.m-l-4 {
	margin-left: 1rem;
}

.m-l-5 {
	margin-left: 1.25rem;
}

.m-l-6 {
	margin-left: 1.5rem;
}

.m-l-7 {
	margin-left: 1.75rem;
}

.m-l-8 {
	margin-left: 2rem;
}

.m-l-9 {
	margin-left: 2.25rem;
}

.m-l-10 {
	margin-left: 2.5rem;
}

.m-l-11 {
	margin-left: 2.75rem;
}

.m-l-12 {
	margin-left: 3rem;
}

.m-l-13 {
	margin-left: 3.25rem;
}

.m-l-14 {
	margin-left: 3.5rem;
}

.m-l-15 {
	margin-left: 3.75rem;
}

.m-l-16 {
	margin-left: 4rem;
}

.m-l-17 {
	margin-left: 4.25rem;
}

.m-l-18 {
	margin-left: 4.5rem;
}

.m-l-19 {
	margin-left: 4.75rem;
}

.m-l-20 {
	margin-left: 5rem;
}

@media (max-width: 479px) {
	.m-l-xs-0 {
		margin-left: 0;
	}

	.m-l-xs-0-5 {
		margin-left: 0.125rem;
	}

	.m-l-xs-1 {
		margin-left: 0.25rem;
	}

	.m-l-xs-2 {
		margin-left: 0.5rem;
	}

	.m-l-xs-3 {
		margin-left: 0.75rem;
	}

	.m-l-xs-4 {
		margin-left: 1rem;
	}

	.m-l-xs-5 {
		margin-left: 1.25rem;
	}

	.m-l-xs-6 {
		margin-left: 1.5rem;
	}

	.m-l-xs-7 {
		margin-left: 1.75rem;
	}

	.m-l-xs-8 {
		margin-left: 2rem;
	}

	.m-l-xs-9 {
		margin-left: 2.25rem;
	}

	.m-l-xs-10 {
		margin-left: 2.5rem;
	}

	.m-l-xs-11 {
		margin-left: 2.75rem;
	}

	.m-l-xs-12 {
		margin-left: 3rem;
	}

	.m-l-xs-13 {
		margin-left: 3.25rem;
	}

	.m-l-xs-14 {
		margin-left: 3.5rem;
	}

	.m-l-xs-15 {
		margin-left: 3.75rem;
	}

	.m-l-xs-16 {
		margin-left: 4rem;
	}

	.m-l-xs-17 {
		margin-left: 4.25rem;
	}

	.m-l-xs-18 {
		margin-left: 4.5rem;
	}

	.m-l-xs-19 {
		margin-left: 4.75rem;
	}

	.m-l-xs-20 {
		margin-left: 5rem;
	}
}

@media (max-width: 1024px) {
	.m-l-sm-0 {
		margin-left: 0;
	}

	.m-l-sm-0-5 {
		margin-left: 0.125rem;
	}

	.m-l-sm-1 {
		margin-left: 0.25rem;
	}

	.m-l-sm-2 {
		margin-left: 0.5rem;
	}

	.m-l-sm-3 {
		margin-left: 0.75rem;
	}

	.m-l-sm-4 {
		margin-left: 1rem;
	}

	.m-l-sm-5 {
		margin-left: 1.25rem;
	}

	.m-l-sm-6 {
		margin-left: 1.5rem;
	}

	.m-l-sm-7 {
		margin-left: 1.75rem;
	}

	.m-l-sm-8 {
		margin-left: 2rem;
	}

	.m-l-sm-9 {
		margin-left: 2.25rem;
	}

	.m-l-sm-10 {
		margin-left: 2.5rem;
	}

	.m-l-sm-11 {
		margin-left: 2.75rem;
	}

	.m-l-sm-12 {
		margin-left: 3rem;
	}

	.m-l-sm-13 {
		margin-left: 3.25rem;
	}

	.m-l-sm-14 {
		margin-left: 3.5rem;
	}

	.m-l-sm-15 {
		margin-left: 3.75rem;
	}

	.m-l-sm-16 {
		margin-left: 4rem;
	}

	.m-l-sm-17 {
		margin-left: 4.25rem;
	}

	.m-l-sm-18 {
		margin-left: 4.5rem;
	}

	.m-l-sm-19 {
		margin-left: 4.75rem;
	}

	.m-l-sm-20 {
		margin-left: 5rem;
	}
}

/* Margin right */
.m-r-0 {
	margin-right: 0;
}

.m-r-0-5 {
	margin-right: 0.125rem;
}

.m-r-1 {
	margin-right: 0.25rem;
}

.m-r-2 {
	margin-right: 0.5rem;
}

.m-r-3 {
	margin-right: 0.75rem;
}

.m-r-4 {
	margin-right: 1rem;
}

.m-r-5 {
	margin-right: 1.25rem;
}

.m-r-6 {
	margin-right: 1.5rem;
}

.m-r-7 {
	margin-right: 1.75rem;
}

.m-r-8 {
	margin-right: 2rem;
}

.m-r-9 {
	margin-right: 2.25rem;
}

.m-r-10 {
	margin-right: 2.5rem;
}

.m-r-11 {
	margin-right: 2.75rem;
}

.m-r-12 {
	margin-right: 3rem;
}

.m-r-13 {
	margin-right: 3.25rem;
}

.m-r-14 {
	margin-right: 3.5rem;
}

.m-r-15 {
	margin-right: 3.75rem;
}

.m-r-16 {
	margin-right: 4rem;
}

.m-r-17 {
	margin-right: 4.25rem;
}

.m-r-18 {
	margin-right: 4.5rem;
}

.m-r-19 {
	margin-right: 4.75rem;
}

.m-r-20 {
	margin-right: 5rem;
}

@media (max-width: 1024px) {
	.m-r-sm-0 {
		margin-right: 0;
	}

	.m-r-sm-0-5 {
		margin-right: 0.125rem;
	}

	.m-r-sm-1 {
		margin-right: 0.25rem;
	}

	.m-r-sm-2 {
		margin-right: 0.5rem;
	}

	.m-r-sm-3 {
		margin-right: 0.75rem;
	}

	.m-r-sm-4 {
		margin-right: 1rem;
	}

	.m-r-sm-5 {
		margin-right: 1.25rem;
	}

	.m-r-sm-6 {
		margin-right: 1.5rem;
	}

	.m-r-sm-7 {
		margin-right: 1.75rem;
	}

	.m-r-sm-8 {
		margin-right: 2rem;
	}

	.m-r-sm-9 {
		margin-right: 2.25rem;
	}

	.m-r-sm-10 {
		margin-right: 2.5rem;
	}

	.m-r-sm-11 {
		margin-right: 2.75rem;
	}

	.m-r-sm-12 {
		margin-right: 3rem;
	}

	.m-r-sm-13 {
		margin-right: 3.25rem;
	}

	.m-r-sm-14 {
		margin-right: 3.5rem;
	}

	.m-r-sm-15 {
		margin-right: 3.75rem;
	}

	.m-r-sm-16 {
		margin-right: 4rem;
	}

	.m-r-sm-17 {
		margin-right: 4.25rem;
	}

	.m-r-sm-18 {
		margin-right: 4.5rem;
	}

	.m-r-sm-19 {
		margin-right: 4.75rem;
	}

	.m-r-sm-20 {
		margin-right: 5rem;
	}
}