.circle-background,
.circle-progress {
	fill: none;
}

.circle-background {
	stroke: #fce6e6;
}

.circle-progress {
	stroke: #e52121;
	stroke-linecap: square;
	stroke-linejoin: round;
}

.circle-title {
	font-size: 14px;
	line-height: 14;
	fill: #6d6c79;
}

.circle-percentage {
	font-size: 18px;
	line-height: 18;
	font-weight: bold;
	fill: #2f2d40;
}
