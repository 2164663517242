.grecaptcha-badge {
	display: none;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

button {
	cursor: pointer;
}

* {
	scrollbar-width: thin;
	scrollbar-color: rgb(237, 234, 250);
}
*::-webkit-scrollbar {
	width: 3px;
}
*::-webkit-scrollbar-track {
}
*::-webkit-scrollbar-thumb {
	border-radius: 20px;
	border: 2px solid rgb(237, 234, 250);
}

/* hiding Feedback button for mobile */
@media (max-width: 475px) {
	._hj_feedback_container {
		display: none;
	}
}
