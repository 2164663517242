.DayPicker__withBorder {
	box-shadow: 0 0 0 0;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
	border: 0;
}

.CalendarDay__default {
	border: 0;
}

.CalendarMonth_caption {
	padding-top: 2px;
	font-family: Proxima Nova Semibold;
	font-size: 1rem;
	color: rgb(47, 45, 64);
}

.DayPickerNavigation_button__default {
	border: 0px;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
	border: 0px;
}

.CalendarMonth_table {
	margin-top: 25px;
}

.DayPickerNavigation_button__horizontalDefault {
	top: -3px;
}

.DayPicker_weekHeader {
	border-top: 1px solid rgb(237, 234, 250);
	padding: 15px 13px;
	top: 36px;
}

.DayPicker_weekHeader_ul {
	margin: 21px 0;
}

.DayPicker_weekHeader_li {
	color: #2f2d40;
	font-family: "Proxima Nova";
	font-size: 14px;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
	border-right: 33px solid rgb(125, 96, 255);
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
	border-right: 33px solid rgb(125, 96, 255);
}

.CalendarDay__highlighted_calendar {
	border-radius: 5px;
	background-color: rgb(125, 96, 255);
	color: #fff;
}

.CalendarDay__default:hover {
	border-radius: 5px;
	color: rgb(125, 96, 255);
	border: 0px;
	background-color: rgb(212, 203, 251);
}

.DayPickerKeyboardShortcuts_buttonReset {
	display: none;
}
