/** Proxima Nova **/
@font-face {
  font-family: 'proxima_nova_altblack';
  src: url('Mark Simonson - Proxima Nova Alt Black-webfont.woff2')
      format('woff2'),
    url('Mark Simonson - Proxima Nova Alt Black-webfont.woff') format('woff'),
    url('Mark Simonson - Proxima Nova Alt Black-webfont.ttf') format('truetype'),
    url('Mark Simonson - Proxima Nova Alt Black-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'proxima_nova_altbold';
  src: url('Mark Simonson - Proxima Nova Alt Bold-webfont.eot');
  src: url('Mark Simonson - Proxima Nova Alt Bold-webfont.eot')
      format('embedded-opentype'),
    url('Mark Simonson - Proxima Nova Alt Bold-webfont.woff2') format('woff2'),
    url('Mark Simonson - Proxima Nova Alt Bold-webfont.woff') format('woff'),
    url('Mark Simonson - Proxima Nova Alt Bold-webfont.ttf') format('truetype'),
    url('Mark Simonson - Proxima Nova Alt Bold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('Mark Simonson - Proxima Nova Alt Regular-webfont.eot');
  src: url('Mark Simonson - Proxima Nova Alt Regular-webfont.eot')
      format('embedded-opentype'),
    url('Mark Simonson - Proxima Nova Alt Regular-webfont.woff') format('woff'),
    url('Mark Simonson - Proxima Nova Alt Regular-webfont.ttf')
      format('truetype'),
    url('Mark Simonson - Proxima Nova Alt Regular-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 4, 2015 */
@font-face {
  font-family: 'proxima_nova_scosfthin';
  src: url('Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot');
  src: url('Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot')
      format('embedded-opentype'),
    url('Mark Simonson - Proxima Nova ScOsf Thin-webfont.woff') format('woff'),
    url('Mark Simonson - Proxima Nova ScOsf Thin-webfont.ttf')
      format('truetype'),
    url('Mark Simonson - Proxima Nova ScOsf Thin-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'proxima_nova_ltsemibold';
  src: url('Mark Simonson - Proxima Nova Semibold-webfont.eot');
  src: url('Mark Simonson - Proxima Nova Semibold-webfont.eot')
      format('embedded-opentype'),
    url('Mark Simonson - Proxima Nova Semibold-webfont.ttf') format('truetype'),
    url('Mark Simonson - Proxima Nova Semibold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('Mark Simonson - Proxima Nova Semibold-webfont.eot');
  src: url('Mark Simonson - Proxima Nova Semibold-webfont.eot')
      format('embedded-opentype'),
    url('Mark Simonson - Proxima Nova Semibold-webfont.ttf') format('truetype'),
    url('Mark Simonson - Proxima Nova Semibold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'proxima_nova_ltthin';
  src: url('Mark Simonson - Proxima Nova Thin-webfont.eot');
  src: url('Mark Simonson - Proxima Nova Thin-webfont.eot')
      format('embedded-opentype'),
    url('Mark Simonson - Proxima Nova Thin-webfont.woff2') format('woff2'),
    url('Mark Simonson - Proxima Nova Thin-webfont.woff') format('woff'),
    url('Mark Simonson - Proxima Nova Thin-webfont.ttf') format('truetype'),
    url('Mark Simonson - Proxima Nova Thin-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
